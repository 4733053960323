import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";
import PageTitle from "../../components/common/PageTitle";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class LearningResourceTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
    };
  }

  deleteClass = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/learning-resource-type/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/learning-resources-type";
        }
      });
  };

  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/learning-resources-type/edit");
  };

  getTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources-types`)
      .then((res) => {
        this.setState({
          types: res.data.resources,
        });
      });
  };

  componentDidMount() {
    this.getTypes();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Learning Resources"
              subtitle="Add new resource type"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() =>
                  this.props.history.push("/learning-resources-type/add")
                }
              >
                Add New resource Type
              </Button>
            </div>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <div className="lead font-weight-bold">Type</div>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Type
                        </th>
                        <th scope="col" className="border-0 exclude-pdf">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.types.map((item, index) => (
                        <tr key={index}>
                          <td> {item.title}</td>
                          <td>
                            <Button onClick={() => this.selectItems(item._id)}>
                              Edit Type
                            </Button>
                            <br />

                            <br />
                            <Button
                              theme="danger"
                              onClick={() => this.deleteClass(item._id)}
                            >
                              Delete Type
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(LearningResourceTypeList);
