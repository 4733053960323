import * as actionType from "../actions/type";
const INITIAL_STATE = {
  id: 0,
};

export const itemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_ITEMS:
      return { ...state, id: action.payload.newItems };

    default:
      return state;
  }
};

export default itemReducer;
