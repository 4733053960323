import React from "react";
import axios from "axios";
import { Button } from "shards-react";

class StudentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  getProfile = () => {
    const id = localStorage.getItem("id");
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/admin/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
        });
      });
  };
  componentDidMount() {
    this.getProfile();
  }
  render() {
    return (
      <React.Fragment>
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="list-back">
              <Button href="/student-list"> Back </Button>
            </div>

            {this.state.data.map((item, index) => (
              <div className="col-md-12 col-lg-12 student-profile ">
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <img src={item.profile_pic} alt="hey" className="w-100" />
                  </div>
                  <div className="col-md-5 col-lg-5 student-data ">
                    <p>Name : {item.name}</p>
                    <p>UID : {item.uid}</p>
                    <p>Location : {item.location}</p>
                  </div>
                </div>
                <hr />
                <h4> Progress data </h4>
                <br />
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <p>
                      Total Number of event : {item.progress_data.eventsCount}
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <p> Progress : {item.progress_data.progress}</p>
                  </div>
                </div>
                <hr />
                <h4> Profile Details </h4>
                <br />
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <p> Class : {item.class}</p>
                    <p> School : {item.school} </p>
                    <p> Board : {item.reference} </p>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <p> Email : {item.email_id} </p>
                    <p> Contact Number : {item.contact_number}</p>
                    <p> Gender : {item.gender === 1 ? "Male" : "Female"}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default StudentProfile;
