import React from "react";
import axios from "axios";
import PageTitle from "../../components/common/PageTitle";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const tabs = {
  pending: {
    rule: null,
    label: "Pending",
  },
  approved: {
    rule: true,
    label: "Approved",
  },
  rejected: {
    rule: false,
    label: "Rejected",
  },
};

class BlogList extends React.Component {
  constructor(props) {
    const tab = new URLSearchParams(window.location.search).get("tab");
    super(props);
    this.state = {
      data: [],
      is_approved: tab ? tabs[tab].rule : null,
      selectedTab: tab ? tab : "pending",
    };
  }

  renderTabs() {
    return (
      <ul className="nav nav-tabs">
        {Object.keys(tabs).map((key, index) => {
          let isActive = this.state.selectedTab === key ? "active" : "";
          return (
            <li className="nav-item" key={`tab${index}`}>
              <a
                className={`nav-link ${isActive}`}
                onClick={() => {
                  window.location.href = `/blog-list?tab=${key}`;
                }}
              >
                {tabs[key].label}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  generatePdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#my-table" });
    doc.save("table.pdf");
    doc.autoTable({
      head: [["Name", "Email", "Country"]],
    });
  };

  getBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/blogs?is_admin=true`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.blogs,
        });
      });
  };

  deleteBlog = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/blogs/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  approveBlog = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/blogs/approve/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  rejectBlog = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/blogs/reject/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  componentDidMount() {
    this.getBlogs();
  }

  makeBlogPrivate = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/blogs/make-private/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  makeBlogPublic = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/blogs/make-public/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  makeBlogPrivateExternal = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/blogs/make-private-external/` + id,
        {
          headers: { "x-access-token": localStorage.getItem("jwtToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  makeBlogPublicExternal = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/blogs/make-public-external/` + id,
        {
          headers: { "x-access-token": localStorage.getItem("jwtToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `/blog-list?tab=${this.state.selectedTab}`;
        }
      });
  };

  changeVisibilityAction(blog) {
    if (!blog.is_approved) {
      return null;
    }

    if (blog.is_public) {
      return (
        <div className="mb-3">
          <Button theme="dark" onClick={() => this.makeBlogPrivate(blog.id)}>
            Make Private
          </Button>
        </div>
      );
    }

    return (
      <div className="mb-3">
        <Button theme="warning" onClick={() => this.makeBlogPublic(blog.id)}>
          Make Public
        </Button>
      </div>
    );
  }

  changeExternalVisibilityAction(blog) {
    if (!blog.is_approved) {
      return null;
    }

    if (blog.is_external) {
      return (
        <div className="mb-3">
          <Button
            theme="dark"
            onClick={() => this.makeBlogPrivateExternal(blog.id)}
          >
            Make Private (External)
          </Button>
        </div>
      );
    }

    return (
      <div className="mb-3">
        <Button
          theme="warning"
          onClick={() => this.makeBlogPublicExternal(blog.id)}
        >
          Make Public (External)
        </Button>
      </div>
    );
  }

  render() {
    const blogs = this.state.data.filter(
      (blog) => blog.is_approved === this.state.is_approved
    );
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Blogs"
              subtitle="blogs"
              className="text-sm-left"
            />
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  {this.renderTabs()}
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Id
                        </th>
                        <th scope="col" className="border-0">
                          Title
                        </th>
                        <th scope="col" className="border-0">
                          Cover
                        </th>
                        <th scope="col" className="border-0">
                          Author
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogs.length ? (
                        blogs.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.id}</td>
                              <td>
                                <div>{item.title}</div>
                                <hr className="mt-1 mb-2" />
                                <p className="small text-muted">
                                  {item.excerpt}
                                </p>
                              </td>
                              <td>
                                <img
                                  src={item.cover}
                                  style={{ width: "200px" }}
                                  alt="cover-img"
                                ></img>
                              </td>
                              <td>
                                {item.student_data[0].first_name}
                                <br />
                                <small className="text-muted">
                                  ({item.student_data[0].email_id})
                                </small>
                              </td>
                              <td>
                                <div className="mb-3">
                                  <a
                                    className="btn btn-info"
                                    href={`${process.env.REACT_APP_PLATFORM_URL}/singleblog/${item.id}`}
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                </div>
                                {item.is_approved === null && (
                                  <>
                                    <div className="mb-3">
                                      <Button
                                        theme="success"
                                        onClick={() =>
                                          this.approveBlog(item.id)
                                        }
                                      >
                                        Approve
                                      </Button>
                                    </div>
                                    <div className="mb-3">
                                      <Button
                                        theme="warning"
                                        onClick={() => this.rejectBlog(item.id)}
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  </>
                                )}
                                {this.changeVisibilityAction(item)}
                                {this.changeExternalVisibilityAction(item)}
                                <div>
                                  <Button
                                    theme="danger"
                                    onClick={() => this.deleteBlog(item.id)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="text-center">
                          <td colspan="5">No {this.state.selectedTab} blog</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default BlogList;
