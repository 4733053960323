export default function () {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">developer_board</i>',
      htmlAfter: "",
    },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    {
      title: "Events",
      htmlBefore: '<i class="material-icons">event</i>',
      to: "/event",
    },

    {
      title: "Members",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/student-list",
    },
    {
      title: "IAIIF 2024",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/impact-festival",
    },
    {
      title: "News",
      htmlBefore: '<i class="material-icons">new_releases</i>',
      to: "/news-list",
    },
    {
      title: "Let's Learn",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/courses-list",
    },
    {
      title: "Modules",
      htmlBefore:
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">view_module</i>',
      to: "/module-list",
    },
    {
      title: "Topics",
      htmlBefore:
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">view_module</i>',
      to: "/topic-list",
    },
    {
      title: "Blogs",
      htmlBefore: '<i class="material-icons">rss_feed</i>',
      to: "/blog-list",
    },
    {
      title: "Tags",
      htmlBefore:
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">local_offer</i>',
      to: "/tag-list",
    },
    {
      title: "What's new",
      htmlBefore: '<i class="material-icons">create</i>',
      to: "/whats-new",
    },
    {
      title: "Learning Resources",
      htmlBefore: '<i class="material-icons">local_library</i>',
      to: "/learning-resources",
    },
    {
      title: "Classes",
      htmlBefore:
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">class</i>',
      to: "/learning-resources-class",
    },
    {
      title: "Resources Types",
      htmlBefore:
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">category</i>',
      to: "/learning-resources-type",
    },
    {
      title: "AI Projects",
      htmlBefore: '<i class="material-icons">library_books</i>',
      to: "/ai-projects",
    },
    {
      title: "CBPs List",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/cbp-list",
    },
    {
      title: "Uploaded List",
      htmlBefore: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">view_module</i>',
      to: "/cbp-list",
    },
    {
      title: "Errors Reported List",
      htmlBefore: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="material-icons">view_module</i>',
      to: "/cbp-errors",
    },
  ];
}
