import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import { Container, Row, Col, Card, CardHeader, Button } from "shards-react";

const ImpactFestivalDashboard = () => {
  const [listLoaded, setListLoaded] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    setListLoaded(false);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/admin/impact-festival/participants`,
        {
          headers: { "x-access-token": localStorage.getItem("jwtToken") },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setListLoaded(true);
      });
  }, []);

  const csvHeaders = [
    { key: "sId", label: "Facilitator ID" },
    { key: "email_id", label: "Email" },
    { key: "first_name", label: "First name" },
    { key: "middle_name", label: "Middle name" },
    { key: "last_name", label: "Last name" },
    { key: "gender", label: "Gender" },
    { key: "class", label: "Class" },
    { key: "school", label: "Institution" },
    { key: "school_type", label: "Institution type" },
    { key: "city", label: "City" },
    { key: "state", label: "State" },
    { key: "country", label: "Country" },
    { key: "iaif24_category", label: "Category" },
    { key: "iaif24_subCategory", label: "Sub category" },
    { key: "iaif24_eligible", label: "Eligible for special award" },
    {
      key: "iaif24_specialAwardCategory",
      label: "Special award category",
    },
    {
      key: "iaif24_specialAwardSubCategory",
      label: "Special award sub category",
    },
    { key: "iaif24_aspirationalDistt", label: "Aspirational district" },
    { key: "iaif24_sdg", label: "UN SDG" },
    { key: "iaif24_ethicalConcern", label: "Ethical concern" },
    { key: "iaif24_projectPDF", label: "Project PDF" },
    { key: "iaif24_projectVideo", label: "Project video" },
    { key: "iaif24_projectType", label: "Project type" },
    { key: "iaif24_projectMembers", label: "Project members (Old)" },
    { key: "iaif24_projectMember1", label: "Project member 1" },
    { key: "iaif24_projectMember2", label: "Project member 2" },
    { key: "iaif24_projectMember3", label: "Project member 3" },
    { key: "iaif24_referralCode", label: "Referral code" },
    { key: "iaif24_status", label: "Submission status" },
    { key: "iaif24_submissionDate", label: "Submission date" },
  ];

  return (
    <>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="India AI Impact Festival 2024 List"
            subtitle=""
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row-cols-10 d-flex flex-column flex-md-row justify-content-between">
                  <div>
                    {listLoaded ? (
                      <CSVLink
                        data={data}
                        headers={csvHeaders}
                        filename="iaiif24.csv"
                        target="_self"
                      >
                        <Button>Download CSV File</Button>
                      </CSVLink>
                    ) : (
                      <Button disabled={true}>Gathering data...</Button>
                    )}
                  </div>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImpactFestivalDashboard;
