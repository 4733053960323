import React from "react";
import axios from "axios";

import { Button } from "shards-react";

class AIProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: "",
      title: "",
      students: [
        { name: "", school: "" },
        { name: "", school: "" },
        { name: "", school: "" },
      ],
      description: "",
      videoURL: "",
      order: "1",
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.mode === "edit") {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/ai-project/${localStorage.getItem(
            "id"
          )}`,
          {
            ...this.state,
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/ai-projects";
          } else {
            console.log("error");
          }
        });
    } else {
      const project = { ...this.state };
      project.students = project.students.filter(
        (student) => student.name !== "" && student.school !== ""
      );
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/ai-project`,
          {
            ...project,
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/ai-projects";
          } else {
            console.log("error");
          }
        });
    }
  };

  componentDidMount() {
    if (this.props.mode === "edit") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/ai-project/${localStorage.getItem(
            "id"
          )}`,
          {
            headers: { "x-access-token": localStorage.getItem("jwtToken") },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const project = res.data.project;
            project.badges = project.badges.join();
            delete project._id;
            this.setState({ ...project });
          } else {
            console.log("error");
          }
        });
    }
  }

  render() {
    const { badges, title, students, description, videoURL, order } =
      this.state;
    return (
      <React.Fragment>
        <section className="hero-section py-50">
          <div className="container">
            <div className="col-md-12">
              <h1 className="event-title">
                {this.props.mode === "edit" ? "Edit" : "Add"} Project
              </h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form onSubmit={this.onSubmit}>
                    <div className="mr-4">
                      <label className="pb-1">
                        Badges (Enter comma separated values)
                      </label>
                      <input
                        type="text"
                        value={badges}
                        onChange={(e) =>
                          this.setState({ badges: e.target.value })
                        }
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>

                    <label className="pb-1">Title</label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        value={title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>

                    <label className="pb-1">Description</label>
                    <div className="input-group input-group-merge">
                      <textarea
                        type="textarea"
                        value={description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        className="form-control"
                        rows={5}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="input-group input-group-merge">
                      <div></div>
                    </div>
                    <label className="pb-1">
                      Youtube Video ID (Example- e7JTaPMf7Q)
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        id="link"
                        type="text"
                        value={videoURL}
                        onChange={(e) =>
                          this.setState({ videoURL: e.target.value })
                        }
                        className="form-control"
                        placeholder="Resource link"
                        required
                      />
                    </div>

                    <label className="pb-1">Order:</label>
                    <div className="input-group input-group-merge">
                      <input
                        id="order"
                        type="number"
                        value={order}
                        onChange={(e) =>
                          this.setState({ order: e.target.value })
                        }
                        className="form-control"
                        placeholder="Order"
                        required
                      />
                    </div>

                    <h4 className="mt-3 mb-0">Member details (Max three)</h4>

                    {students.map((student, index) => {
                      return (
                        <div className="d-flex">
                          <div className="w-100 mr-4">
                            <label className="pb-1">Name</label>
                            <input
                              type="text"
                              value={student.name}
                              onChange={(e) => {
                                const students = this.state.students;
                                students[index].name = e.target.value;

                                this.setState({ students });
                              }}
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                          <div className="w-100">
                            <label className="pb-1">School</label>
                            <input
                              type="text"
                              value={student.school}
                              onChange={(e) => {
                                const students = this.state.students;
                                students[index].school = e.target.value;

                                this.setState({ students });
                              }}
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      );
                    })}
                    <Button type="submit" className="submit-btn">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AIProjectForm;
