/* eslint-disable default-case */
import React from "react";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button } from "shards-react";

class EditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      image: "",
    };
  }
  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },
          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  createNotification = (type) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Post",
          1000
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Retry again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
    }
    // };
  };
  getCourse = () => {
    const id = localStorage.getItem("id");
    axios
      .get(`${process.env.REACT_APP_API_URL}/courses/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.courses,
          image: res.data.courses[0].cover,
        });
      });
  };
  componentDidMount() {
    this.getCourse();
  }
  onSubmit = (e) => {
    const id = localStorage.getItem("id");
    e.preventDefault();
    if (this.state.image === "") {
      this.createNotification("error");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/courses/` + id,
          {
            title: e.target.title.value,
            cover: this.state.image,
            courseType: e.target.courseType.value,
            desc: e.target.desc.value,
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });

      window.location.href = "/courses-list";
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Edit Course</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  {this.state.data.map((item, index) => (
                    <form
                      key={index}
                      // className="login-signup-form"
                      onSubmit={this.onSubmit}
                    >
                      <label className="pb-1">Course Cover Image</label>
                      <div className="input-group input-group-merge">
                        <input
                          onChange={this.fileSelectedHandler}
                          type="file"
                          name="image"
                          className="form-control"
                          placeholder=""
                        />
                        <Button type="button" onClick={this.fileUploadHandler}>
                          Upload Cover
                        </Button>
                      </div>

                      <div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.state.image}
                        >
                          {this.state.image}
                        </a>
                      </div>
                      <label className="pb-1">Course Title</label>
                      <div className="input-group input-group-merge">
                        <input
                          defaultValue={item.title}
                          onChange={this.onChange}
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder="Event Title"
                          required
                        />
                      </div>

                      <label className="pb-1">Select Course Type</label>
                      <div className="input-group input-group-merge">
                        <select
                          onChange={this.onChange}
                          name="courseType"
                          className="form-select form-control"
                          required
                          style={{ width: "100%" }}
                          defaultValue={this.state.data[0].courseType} 
                        >
                          <option value="">-- Select Course Type --</option>
                          <option value="basic">Basic</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="expert">Expert</option>
                        </select>
                      </div>

                      <label className="pb-1">Course Desc</label>
                      <div className="input-group input-group-merge">
                        <textarea
                          defaultValue={item.desc}
                          onChange={this.onChange}
                          type="textarea"
                          name="desc"
                          className="form-control"
                          placeholder=""
                          rows={5}
                          required
                        />
                      </div>
                      <Button
                        type="submit"
                        value="submit"
                        // onClick={this.onSubmit}
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </form>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default EditCourse;
