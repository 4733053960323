import React, { Component } from "react";
import axios from "axios";
import "../../sidebar/style.css";
import DatetimeRangePicker from "react-datetime-range-picker";
import DateTime from "react-datetime";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Button, FormInput } from "shards-react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_date: [],
      scheduledDate: new Date(),
      type: "",
      challenge: false,
      gplace: [],
      startDate: new Date(),
      endDate: new Date(),
      finaltag: [],
      address: "",
      locationName: "",
      latLng: "",
      image: null,
      tag: "",
      title: "",
      desc: "",
      organizer: "",
      url: "",
      url_label: "",
      upload: false,
    };
    this.handleChallengeInput = this.handleChallengeInput.bind(this);
  }
  handle = (place) => {
    this.setState({
      gplace: place,
    });
  };
  handleDate = (date) => {
    this.setState({
      event_date: date,
    });
  };

  handleScheduleDate = (date) => {
    this.setState({
      scheduledDate: date,
    });
  };

  handleChallengeInput(event) {
    const target = event.target;
    const value = target.checked;

    this.setState({
      challenge: value,
    });
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          latLng: latLng,
        });
      })
      .then((res) => {
        this.setState({
          locationName: address,
          address: address,
        });
      })

      .catch((error) => console.error("Error", error));
  };
  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === "") {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },

          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };
  createNotification = (type) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Post",
          1000
        );
        this.UpdateChecker();
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Retry again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
      default:
        alert("something technical issue");
    }
  };
  UpdateChecker = () => {
    if (this.state.upload === true) {
      this.props.history.push("/event");
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.image === null) {
      this.createNotification("error");
    } else {
      const tag = this.state.tag;
      const afterSplit = tag.split(" ");
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.finaltag = afterSplit;

      fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwtToken"),
        },
        body: JSON.stringify({
          title: this.state.title,
          cover: this.state.image,
          desc: this.state.desc,
          type: this.state.type,
          challenge: this.state.challenge,
          url: this.state.url,
          url_label: this.state.url_label
            ? this.state.url_label
            : "Meeting Link",
          location: {
            locationName: this.state.locationName,
            lat: this.state.latLng.lat,
            lon: this.state.latLng.lng,
          },
          start_time: this.state.event_date.start,
          end_time: this.state.event_date.end,
          scheduled_date: this.state.scheduledDate,
          event_creator: this.state.organizer,
          tags: this.state.finaltag,
        }),
      }).then((res) => {
        if (res.status === 200) {
          this.setState = {
            upload: true,
          };
          this.createNotification("success");
        } else {
          this.createNotification("error");
        }
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Add New Event</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form
                    // className="login-signup-form"
                    onSubmit={this.onSubmit}
                  >
                    <label className="pb-1">Mark as Challenge:</label>
                    <input
                      className="mx-2"
                      name="challenge"
                      id="challenge"
                      type="checkbox"
                      checked={this.state.challenge}
                      onChange={this.handleChallengeInput}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <label className="pb-1">Event Cover Image*</label>
                        <div className="input-group input-group-merge">
                          <input
                            // value=''
                            onChange={this.fileSelectedHandler}
                            type="file"
                            name="image"
                            className="form-control"
                            placeholder=""
                            required
                          />
                          <Button onClick={this.fileUploadHandler}>
                            Upload Cover
                          </Button>
                        </div>
                        <label className="pb-1">Event Organizer*</label>
                        <div className="input-group input-group-merge">
                          <input
                            // value=''
                            onChange={(event) =>
                              this.setState({ organizer: event.target.value })
                            }
                            type="text"
                            name="organizer"
                            className="form-control"
                            placeholder="Event Organizer"
                            required
                          />
                        </div>
                        <label className="pb-1">Event Time</label>
                        <DatetimeRangePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          input={true}
                          onChange={(date) => this.handleDate(date)}
                          onStartDateBlur={this.startDateBlurHandler}
                          onEndDateBlur={this.endDateBlurHandler}
                          timeFormat="HH:mm a"
                          dateFormat="DD/MM/YY"
                          value={this.state.event_date}
                        />
                        <label className="pb-1">Scheduled Date</label>
                        <DateTime
                          input={true}
                          onChange={(date) => this.handleScheduleDate(date)}
                          timeFormat="HH:mm a"
                          dateFormat="DD/MM/YY"
                          value={this.state.scheduledDate}
                        />
                        <label className="pb-1">Event Desc*</label>
                        <div className="input-group input-group-merge">
                          <textarea
                            onChange={(event) =>
                              this.setState({ desc: event.target.value })
                            }
                            type="textarea"
                            name="desc"
                            className="form-control"
                            placeholder=""
                            rows={5}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="pb-1">Event Title*</label>
                        <div className="input-group input-group-merge">
                          <input
                            // value=''
                            onChange={(event) =>
                              this.setState({ title: event.target.value })
                            }
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="Event Title"
                            required
                          />
                        </div>
                        <label className="pb-1">Event Type </label>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="radio-btn-content">
                              <input
                                type="radio"
                                id="online"
                                name="online"
                                checked={this.state.type === "online"}
                                onClick={(event) =>
                                  this.setState({ type: event.target.value })
                                }
                                value="online"
                              />
                              <label htmlFor="online">Online</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="radio-btn-content">
                              <input
                                type="radio"
                                id="offline"
                                name="offline"
                                checked={this.state.type === "offline"}
                                onClick={(event) =>
                                  this.setState({ type: event.target.value })
                                }
                                value="offline"
                              />
                              <label htmlFor="offline">Offline</label>
                            </div>
                          </div>
                        </div>
                        <label className="pb-1">Event Tag*</label>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={(event) =>
                              this.setState({ tag: event.target.value })
                            }
                            type="text"
                            name="tag"
                            className="form-control"
                            placeholder=""
                            required
                          />
                        </div>
                        <label className="pb-1">Enter Location</label>
                        <div className="input-group input-group-merge">
                          <div>
                            <PlacesAutocomplete
                              value={this.state.address}
                              onChange={this.handleChange}
                              onSelect={this.handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <FormInput
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "location-search-input",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>
                        <label className="pb-1">Website URL</label>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={(event) =>
                              this.setState({ url: event.target.value })
                            }
                            type="url"
                            name="url"
                            className="form-control"
                            placeholder="Enter website URl"
                          />
                        </div>
                        <label className="pb-1">URL Label:</label>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={(event) =>
                              this.setState({ url_label: event.target.value })
                            }
                            type="text"
                            name="url"
                            className="form-control"
                            placeholder="Meeting Link"
                          />
                        </div>
                      </div>
                    </div>
                    <Button type="submit" value="submit" className="submit-btn">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AddEvent;
