import React from "react";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button } from "shards-react";

class AddTopic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      image: null,
      upload: false,
      links: [
        {
          title: " ",
          desc: " ",
          link: " ",
        },
      ],
      examples: [
        {
          title: "",
          desc: "",
          link: "",
        },
      ],
    };
  }

  getModule = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/modules`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.modules,
        });
      });
  };
  componentDidMount() {
    this.getModule();
  }

  linkTitle = (i) => (e) => {
    this.state.links[i].title = e.target.value;
  };

  linkDec = (i) => (e) => {
    this.state.links[i].desc = e.target.value;
  };

  linkUrl = (i) => (e) => {
    this.state.links[i].link = e.target.value;
  };

  exampleTitle = (i) => (e) => {
    this.state.examples[i].title = e.target.value;
  };

  exampleDec = (i) => (e) => {
    this.state.examples[i].desc = e.target.value;
  };

  exampleUrl = (i) => (e) => {
    this.state.examples[i].link = e.target.value;
  };

  LinkDelete = (i) => (e) => {
    e.preventDefault();
    let links = [
      ...this.state.links.slice(0, i),
      ...this.state.links.slice(i + 1),
    ];

    this.setState({
      links,
    });
  };

  ExampleDelete = (i) => (e) => {
    let examples = [
      ...this.state.examples.slice(0, i),
      ...this.state.examples.slice(i + 1),
    ];

    this.setState({
      examples,
    });
  };

  addExample = (e) => {
    e.preventDefault();
    let examples = this.state.examples.concat([
      {
        title: " ",
        desc: " ",
        link: " ",
      },
    ]);
    this.setState({
      examples,
    });
  };

  addLink = (e) => {
    e.preventDefault();
    let links = this.state.links.concat([
      {
        title: " ",
        desc: " ",
        link: " ",
      },
    ]);
    this.setState({
      links,
    });
  };
  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },
          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  createNotification = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Post",
          1000
        );
        this.UpdateChecker();
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Retry again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
    }
  };

  UpdateChecker = () => {
    if (this.state.upload === true) {
      this.props.history.push("/topic-list");
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.image === null) {
      this.createNotification("error");
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/topics`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwtToken"),
        },
        body: JSON.stringify({
          video: e.target.url.value,
          progress: e.target.progress.value,
          links: this.state.links,
          Experiences: this.state.examples,
          module_id: e.target.topic.value,
          course_id: this.state.data[e.target.topic.value - 1].course_id,
          title: e.target.title.value,
          cover: this.state.image,
          desc: e.target.desc.value,
        }),
      }).then((res) => {
        if (res.status === 200) {
          this.setState = {
            upload: true,
          };
          this.createNotification("success");
        } else {
          this.createNotification("error");
        }
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Add Topic</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form
                    // className="login-signup-form"
                    onSubmit={this.onSubmit}
                  >
                    <label className="pb-1">Topic Cover Image</label>
                    <div className="input-group input-group-merge">
                      <input
                        // value=''
                        onChange={this.fileSelectedHandler}
                        type="file"
                        name="image"
                        className="form-control"
                        placeholder=""
                        required
                      />
                      <Button type="button" onClick={this.fileUploadHandler}>
                        Upload Cover
                      </Button>
                    </div>
                    <label className="pb-1">Topic Video Url</label>
                    <div className="input-group input-group-merge">
                      <input
                        onChange={this.onChange}
                        type="url"
                        name="url"
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>
                    <label className="pb-1"> Progress </label>
                    <div className="input-group input-group-merge">
                      <input
                        // value=''
                        onChange={this.onChange}
                        type="number"
                        name="progress"
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>
                    <label className="pb-1">Topic Title</label>
                    <div className="input-group input-group-merge">
                      <input
                        // value=''
                        onChange={this.onChange}
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Event Title"
                        required
                      />
                    </div>
                    <label className="pb-1">Topic Description</label>
                    <div className="input-group input-group-merge">
                      <textarea
                        onChange={this.onChange}
                        type="textarea"
                        name="desc"
                        className="form-control"
                        placeholder=""
                        rows={5}
                        required
                      />
                    </div>
                    <label className="pb-1">Module for Topic</label>
                    <select
                      name="topic"
                      onChange={this.onChange}
                      className="input-group input-group-merge intel-dropdown"
                    >
                      {this.state.data.map((item) => (
                        <option className="intel-option" value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    <br />
                    <h4 className="pb-1">
                      <i class="material-icons"> link </i> Links
                    </h4>
                    <br />
                    {this.state.links.map((link, index) => (
                      <span key={index}>
                        <div className="input-group input-group-merge">
                          <label className="pb-1"> Link Title </label>
                          <div className="input-group input-group-merge">
                            <input
                              // value=''
                              onChange={this.linkTitle(index)}
                              type="text"
                              name="ltitle"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>
                          <label className="pb-1"> Link Description </label>
                          <div className="input-group input-group-merge">
                            <input
                              // value=''
                              onChange={this.linkDec(index)}
                              type="text"
                              name="ldec"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>

                          <div className="input-group input-group-merge">
                            <label className="pb-1"> Link url </label>
                            <div className="input-group input-group-merge">
                              <input
                                // value=''
                                onChange={this.linkUrl(index)}
                                type="url"
                                name="lurl"
                                className="form-control"
                                placeholder=""
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          onClick={this.LinkDelete(index)}
                          className="close-button "
                        >
                          <i className="material-icons small-icon">&#xe15d; </i>
                          Delete
                        </Button>
                      </span>
                    ))}
                    <Button onClick={this.addLink} className="add-button">
                      <i className="material-icons small-icon">
                        add_circle_outline
                      </i>
                      Add More Link
                    </Button>
                    <div className="input-group input-group-merge"></div>
                    <br />
                    <h4 className="pb-1">
                      <i class="material-icons"> link </i> Experiences
                    </h4>
                    <br />
                    {this.state.examples.map((example, index) => (
                      <span key={index}>
                        <div className="input-group input-group-merge">
                          <label className="pb-1"> Experience Title </label>
                          <div className="input-group input-group-merge">
                            <input
                              // value=''
                              onChange={this.exampleTitle(index)}
                              type="text"
                              name="etitle"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>
                          <label className="pb-1">
                            {" "}
                            Experience Description{" "}
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              // value=''
                              onChange={this.exampleDec(index)}
                              type="text"
                              name="edec"
                              className="form-control"
                              placeholder=""
                              required
                            />
                          </div>

                          <div className="input-group input-group-merge">
                            <label className="pb-1"> Experience url </label>
                            <div className="input-group input-group-merge">
                              <input
                                // value=''
                                onChange={this.exampleUrl(index)}
                                type="url"
                                name="eurl"
                                className="form-control"
                                placeholder=""
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="close-button "
                          onClick={this.ExampleDelete(index)}
                        >
                          <i className="material-icons small-icon">&#xe15d; </i>
                          Delete
                        </Button>
                      </span>
                    ))}
                    <Button className="add-button" onClick={this.addExample}>
                      <i className="material-icons small-icon">
                        add_circle_outline
                      </i>
                      Add More Experience
                    </Button>
                    <div className="input-group input-group-merge">
                      <Button
                        type="submit"
                        value="submit"
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AddTopic;
