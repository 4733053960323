import React, { Component } from "react";
import "../../sidebar/style.css";
import axios from "axios";
import { Button } from "shards-react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class CBPListUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      desc: null,
      upload: false,
      term: null,
      eventEndDate: null,
      eventStartDate: null,
      className: null,
      cbpID: null,
      errors: null,
    };
  }

  fetchCBPData = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cbp/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        const {
          name,
          description,
          term,
          _id,
          className,
          eventStartDate,
          eventEndDate,
        } = res.data.metadata;
        this.setState({
          name,
          desc: description,
          term,
          cbpID: _id,
          className,
          eventStartDate: new Date(eventStartDate).toISOString().split("T")[0],
          eventEndDate: new Date(eventEndDate).toISOString().split("T")[0],
        });
      })
      .catch((err) => {
        console.error(err);
        this.createNotification("error");
      });
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  createNotification = (type, message) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Posted",
          1000
        );
        this.UpdateChecker();
        break;
      case "error":
        NotificationManager.error(message, "Error message", 1000);
        break;
      default:
        break;
    }
  };

  UpdateChecker = () => {
    if (this.state.upload === true) {
      this.props.history.push("/cbp-list");
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append("name", this.state.name);
    formData.append("desc", this.state.desc);
    formData.append("term", this.state.term);
    formData.append("className", this.state.className);
    formData.append("eventStartDate", this.state.eventStartDate);
    formData.append("eventEndDate", this.state.eventEndDate);

    let url = `${process.env.REACT_APP_API_URL}/cbp`;
    let method = "POST";

    if (this.state.cbpID) {
      url = `${process.env.REACT_APP_API_URL}/cbp/${this.state.cbpID}`;
      method = "PUT";
    }

    fetch(url, {
      method: method,
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
      },
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            upload: true,
          });
          this.createNotification("success");
        }
        if (res.status === 422) {
          res.json().then((data) => {
            this.setState({ errors: data.error });
          });
        }
      })
      .catch((error) => {
        this.createNotification("error", error);
      });
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.cbpID) {
      this.fetchCBPData(match.params.cbpID);
    }
  }

  render() {

    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Upload CBP List</h1>
              {this.state.errors && this.state.errors.length > 0 && (
                <div className="error-section shadow-lg mb-4">
                  <div className="card-body px-md-5 py-2">
                    <h5 className="text-center text-danger">
                      Errors Found In Sheet
                    </h5>
                    <ul>
                      {this.state.errors.map((error, index) => (
                        <li key={index} className="text text-danger">
                          {error.message}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="pb-1">Name</label>
                        <input
                          onChange={(event) =>
                            this.setState({ name: event.target.value })
                          }
                          value={this.state.name || ""}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Please Enter The Event Name"
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <label className="pb-1 mr-2">Upload CSV*</label>
                          <a
                            href="/assets/Sample.csv"
                            className="underline-link"
                          >
                            Here is a sample file
                          </a>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={this.fileSelectedHandler}
                            type="file"
                            name="file"
                            className="form-control"
                            placeholder=""
                            required
                          />
                          <Button type="submit">Upload List</Button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="pb-1">Description</label>
                        <input
                          onChange={(event) => {
                            this.setState({ desc: event.target.value });
                          }}
                          type="text"
                          value={this.state.desc || ""}
                          name="desc"
                          className="form-control"
                          placeholder=""
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="pb-1">Select Month</label>
                        <input
                          onChange={(event) =>
                            this.setState({ term: event.target.value })
                          }
                          value={this.state.term || ""}
                          type="month"
                          name="term"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="pb-1">Select Class</label>
                        <select
                          onChange={(event) =>
                            this.setState({ className: event.target.value })
                          }
                          value={this.state.className || ""}
                          name="className"
                          className="form-select form-control"
                          required
                        >
                          <option value="">Select Class</option>
                          <option value="Class IX">Class 9</option>
                          <option value="Class X">Class 10</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label className="pb-1">Event Start Date</label>
                        <input
                          onChange={(event) =>
                            this.setState({
                              eventStartDate: event.target.value,
                            })
                          }
                          value={this.state.eventStartDate || ""}
                          type="date"
                          name="eventStartDate"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="pb-1">Event End Date</label>
                        <input
                          onChange={(event) =>
                            this.setState({ eventEndDate: event.target.value })
                          }
                          value={this.state.eventEndDate || ""}
                          type="date"
                          name="eventEndDate"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <Button type="submit" value="submit" className="submit-btn">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CBPListUpload;
