import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";
import PageTitle from "../../components/common/PageTitle";

import { Container, Row, Col, Card, CardBody, Button } from "shards-react";

class WhatsNewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  deleteWhatsNewBlock = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/WhatsNew/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/whats-new";
        }
      });
  };

  getNews = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/WhatsNew?sort_by=_id&order=-1`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
        });
      });
  };
  selectItems = (id) => {
    const items = {
      id: id,
    };

    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/whats-new/edit");
  };

  componentDidMount() {
    this.getNews();
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Whats New Section"
              className="text-sm-left"
            />
          </Row>

          <div className="add-new mb-3">
            <Button
              className="add-new-btn"
              onClick={() => this.props.history.push("/whats-new/create")}
            >
              Add New Block
            </Button>
          </div>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Cover
                        </th>
                        <th scope="col" className="border-0">
                          Description
                        </th>
                        <th scope="col" className="border-0">
                          Block Order
                        </th>
                        <th scope="col" className="border-0">
                          Link
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={item.cover}
                              style={{ width: "200px" }}
                              alt="cover-img"
                            ></img>
                          </td>
                          <td>{item.description}</td>
                          <td>
                            {item.order_by ? (
                              item.order_by
                            ) : (
                              <code className="text-muted text-nowrap p-0">
                                Not set
                              </code>
                            )}
                          </td>
                          <td>{item.link}</td>
                          <td>
                            <Button
                              className="mr-1"
                              onClick={() => this.selectItems(item._id)}
                            >
                              Edit block
                            </Button>

                            <Button
                              theme="danger"
                              onClick={() => this.deleteWhatsNewBlock(item._id)}
                            >
                              Delete Block
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(WhatsNewList);
