import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";
import PageTitle from "../../components/common/PageTitle";
import jsPDF from "jspdf";
// import 'jspdf-autotable'
import autoTable from "jspdf-autotable";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  deleteTopic = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/topics/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/topic-list";
        }
      });
  };

  generatePdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#my-table" });
    doc.save("table.pdf");
    doc.autoTable({
      head: [["Name", "Email", "Country"]],
      // body: [
      //   ['David', 'david@example.com', 'Sweden'],
      //   ['Castille', 'castille@example.com', 'Spain'],
      //   // ...
      // ],
    });
  };
  getCourse = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/topics`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.topics,
        });
      });
  };
  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/edit-topic");
  };
  componentDidMount() {
    this.getCourse();
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title=" Topics"
              subtitle="topics"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/add-topic")}
              >
                Add New Topic
              </Button>
            </div>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Title
                        </th>
                        <th scope="col" className="border-0">
                          Cover
                        </th>
                        <th scope="col" className="border-0">
                          Description
                        </th>
                        <th scope="col" className="border-0">
                          Progress
                        </th>

                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td> {item.title}</td>
                          <td>
                            {" "}
                            <img
                              src={item.cover}
                              style={{ width: "200px" }}
                              alt="cover-img"
                            ></img>
                          </td>
                          <td> {item.desc}</td>
                          <td> {item.progress}</td>
                          <td>
                            <Button
                              onClick={() => this.selectItems(item.id)}
                              className="mb-2"
                            >
                              Edit Topic
                            </Button>
                            <Button
                              onClick={() => this.deleteTopic(item.id)}
                              theme="danger"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(TopicList);
// export default NewsList;
