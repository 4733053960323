import React, { Component } from "react";

class LetsLearn extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Welocome LetsLearn</h1>
      </React.Fragment>
    );
  }
}
export default LetsLearn;
