import React, { Component } from "react";

import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";

import PageTitle from "../../components/common/PageTitle";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class AIProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  deleteAIProject = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/ai-project/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/ai-projects";
        }
      });
  };

  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/ai-projects/edit");
  };
  getAIProjects = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/ai-projects`).then((res) => {
      this.setState({
        data: res.data.projects,
      });
    });
  };

  componentDidMount() {
    this.getAIProjects();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="AI Projects"
              subtitle="Add new project"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/ai-projects/add")}
              >
                Add New AI Project
              </Button>
            </div>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <div style={{ textAlign: "right" }}></div>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Member
                        </th>
                        <th scope="col" className="border-0">
                          Title
                        </th>
                        <th scope="col" className="border-0">
                          Description
                        </th>
                        <th scope="col" className="border-0">
                          Order
                        </th>
                        <th scope="col" className="border-0 exclude-pdf">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.students.map((student) => student.name)}
                          </td>
                          <td> {item.title}</td>

                          <td>
                            <div className="w-200">{item.description}</div>
                          </td>
                          <td>
                            <div>{item.order}</div>
                          </td>

                          <td>
                            <Button onClick={() => this.selectItems(item._id)}>
                              Edit Project
                            </Button>
                            <br />

                            <br />
                            <Button
                              theme="danger"
                              onClick={() => this.deleteAIProject(item._id)}
                            >
                              Delete Project
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(AIProjects);
