import React, { Component } from "react";
import Moment from "react-moment";
import axios from "axios";
import { Button } from "shards-react";

class EventRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  deleteEvent = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/event/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/event";
        }
      });
  };

  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/edit-event");
  };

  copy = (value) => {
    const el = document.createElement("input");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copied: true });
  };

  render() {
    const { data, key } = this.props;
    return (
      <React.Fragment>
        <tr key={key}>
          <td> {data.title}</td>
          <td>
            {data.unique_link &&
              `https://aifacilitator.community/mark-attendance/${data.unique_link}`}
            {data.unique_link && (
              <Button
                theme="outline-light"
                onClick={() =>
                  this.copy(
                    `https://aifacilitator.community/mark-attendance/${data.unique_link}`
                  )
                }
              >
                <i class="material-icons">content_copy</i>
                {!this.state.copied ? "Copy link" : "Copied!"}
              </Button>
            )}
          </td>
          <td>
            {data.tags.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </td>
          <td>
            <Moment format="DD/MM/YYYY">{data.start_time}</Moment>
          </td>
          <td>
            <Moment format="DD/MM/YYYY hh:mm a">{data.scheduled_date}</Moment>
          </td>
          <td>
            <Button onClick={() => this.selectItems(data.id)}>
              Edit Event
            </Button>
            <br />
            <br />
            <Button href={`/participant-list/${data.id}`}>
              View Participant List{" "}
            </Button>
            <br />
            <br />
            <Button href={`/attendee-list/${data.id}`}>
              View Attendees List{" "}
            </Button>
            <br />
            <br />
            <Button theme="danger" onClick={() => this.deleteEvent(data.id)}>
              Delete Event
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default EventRow;
