import React from "react";
import axios from "axios";
import PageTitle from "../../components/common/PageTitle";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";
import { CSVLink } from "react-csv";

class participantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  getEvent = () => {
    let { eventId } = this.props.match.params;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/events/${eventId}/participants?csv=1`,
        {
          headers: { "x-access-token": localStorage.getItem("jwtToken") },
        }
      )
      .then((res) => {
        let data = res.data.students.map(
          (participant) => participant.student_data[0]
        );
        this.setState({
          data: data,
          csv_headers: res.data.csv_headers,
        });
      });
  };
  studentProfile = (id) => {
    localStorage.setItem("id", id);
    this.props.history.push("/student-profile");
  };
  componentDidMount() {
    this.getEvent();
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Participant's List"
              subtitle=""
              className="text-sm-left"
            />
          </Row>

          <div className="back-button">
            <Button href="/event"> Back </Button>
          </div>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Col>
                    <div className="text-right">
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.csv_headers}
                        filename="event-participants.csv"
                        target="_self"
                      >
                        <Button>Download CSV File</Button>
                      </CSVLink>
                    </div>
                  </Col>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          First Name
                        </th>
                        <th scope="col" className="border-0">
                          Last Name
                        </th>

                        <th scope="col" className="border-0">
                          Email
                        </th>
                        <th scope="col" className="border-0">
                          City
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((participant, index) => (
                        <tr key={index}>
                          <td>{participant.first_name}</td>
                          <td>{participant.last_name}</td>
                          <td>{participant.email_id}</td>
                          <td>{participant.city}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default participantList;
