import React from "react";
import axios from "axios";
import PageTitle from "../../components/common/PageTitle";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
} from "shards-react";

class CBPErrorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title: null,
      cbpID: null,
    };
  }

  getErrors = () => {
    let { cbpID } = this.props.match.params;
    axios
      .get(`${process.env.REACT_APP_API_URL}/cbp-errors/${cbpID}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
          title: res.data.name,
          cbpID: res.data._id,
        });
      });
  };

  componentDidMount() {
    this.getErrors();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              subtitle="Reported Errors"
              title={this.state.title}
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                href={`/cbp-list-edit/${this.state.cbpID}`}
                type="button"
                onClick={this.handleUpdate}
              >
                Upload Corrected Sheet
              </Button>
            </div>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          S.N.
                        </th>
                        <th scope="col" className="border-0">
                          Name Of Participant
                        </th>
                        <th scope="col" className="border-0">
                          Email ID
                        </th>
                        <th scope="col" className="border-0">
                          Error Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data && this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td> {item.error} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row noGutters className="d-flex justify-content-end"></Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default CBPErrorsList;
