import React, { Component } from "react";
import axios from "axios";
import "../../sidebar/style.css";
import DatetimeRangePicker from "react-datetime-range-picker";
import DateTime from "react-datetime";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";
import { Button, FormInput, Row, Col } from "shards-react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class EditEvent extends Component {
  constructor(props) {
    const id = localStorage.getItem("id");
    super(props);
    this.state = {
      id: id,
      data: [],
      event_date: [],
      scheduledDate: new Date(),
      type: "",
      challenge: false,
      gplace: [],
      startDate: new Date(),
      endDate: new Date(),
      finaltag: [],
      address: "",
      locationName: "",
      latLng: "",
      image: "",
      tag: "",
      title: "",
      desc: "",
      organizer: "",
      url: "",
      urlLabel: "",
    };
    this.handleChallengeInput = this.handleChallengeInput.bind(this);
  }
  createNotification = (type) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Update",
          1000
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Retry again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
      default:
        alert("something technical issue");
    }
    // };
  };
  handle = (place) => {
    this.setState({
      gplace: place,
    });
  };
  handleDate = (date) => {
    this.setState({
      event_date: date,
    });
  };
  handleScheduleDate = (date) => {
    this.setState({
      scheduledDate: date,
    });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleChallengeInput(event) {
    const target = event.target;
    const value = target.checked;

    this.setState({
      challenge: value,
    });
  }

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          latLng: latLng,
        });
      })
      .then((res) => {
        this.setState({
          locationName: address,
          address: address,
        });
      })

      .catch((error) => console.error("Error", error));
  };
  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };
  getEvent = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/events/` + this.state.id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.events,
          image: res.data.events[0].cover,
          scheduledDate: new Date(res.data.events[0].scheduled_date),
          challenge: res.data.events[0].challenge,
        });
      });
  };
  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },
          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.image === "") {
      this.createNotification("error");
    } else {
      const tag = e.target.tags.value;
      const tags = tag.split(",").map((rawTag) => rawTag.trim());
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.finaltag = tags;

      fetch(`${process.env.REACT_APP_API_URL}/events/` + this.state.id, {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwtToken"),
        },
        body: JSON.stringify({
          title: e.target.title.value,
          cover: this.state.image,
          desc: e.target.desc.value,
          type: this.state.type,
          challenge: this.state.challenge,
          url: e.target.url.value,
          url_label: e.target.urlLabel.value
            ? e.target.urlLabel.value
            : "Meeting Link",
          location: {
            locationName: this.state.locationName,
            lat: this.state.latLng.lat,
            lon: this.state.latLng.lng,
          },
          start_time: this.state.event_date.start,
          end_time: this.state.event_date.end,
          scheduled_date: this.state.scheduledDate,
          event_creator: e.target.organizer.value,
          tags: this.state.finaltag,
        }),
      }).then((res) => {
        if (res.status === 200) {
          this.createNotification("success");
        } else {
          this.createNotification("error");
        }
      });
      this.props.history.push("/event");
    }
  };

  componentDidMount() {
    this.getEvent();
  }

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Edit Event</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  {data.map((item, index) => (
                    <form
                      className="login-signup-form"
                      key={index}
                      onSubmit={this.onSubmit}
                    >
                      <label className="pb-1">Mark as Challenge: </label>
                      <input
                        className="mx-2"
                        name="challenge"
                        id="challenge"
                        type="checkbox"
                        checked={this.state.challenge}
                        onChange={this.handleChallengeInput}
                      />
                      <Row>
                        <Col md="6" className="form-group">
                          <label className="pb-1">Event Cover Image</label>
                          <div className="input-group input-group-merge">
                            <input
                              // defaultValue={item.cover}
                              onChange={this.fileSelectedHandler}
                              id="image"
                              type="file"
                              name="image"
                              className="form-control"
                              placeholder=""
                            />
                            <Button
                              type="button"
                              onClick={this.fileUploadHandler}
                            >
                              Upload Cover
                            </Button>
                          </div>
                          <div>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={this.state.image}
                            >
                              {this.state.image}
                            </a>
                          </div>
                          <label className="pb-1">Event Organizer</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={item.event_creator}
                              onChange={this.onChange}
                              type="text"
                              id="organizer"
                              name="organizer"
                              className="form-control"
                              placeholder="Event Organizer"
                              required
                            />
                          </div>
                          <label className="pb-1">Event Time</label>

                          <DatetimeRangePicker
                            startDate={item.start_time}
                            endDate={item.end_time}
                            input={true}
                            onChange={(date) => this.handleDate(date)}
                            onStartDateBlur={this.startDateBlurHandler}
                            onEndDateBlur={this.endDateBlurHandler}
                            timeFormat="HH:mm a"
                            dateFormat="DD/MM/YY"
                            value={this.state.event_date}
                          />
                          <label className="pb-1">Scheduled Date</label>
                          <DateTime
                            value={this.state.scheduledDate}
                            input={true}
                            onChange={(date) => this.handleScheduleDate(date)}
                            dateFormat="DD/MM/YY"
                            timeFormat="hh:mm a"
                          />
                          <label className="pb-1">Event Desc</label>
                          <div className="input-group input-group-merge">
                            <textarea
                              defaultValue={item.desc}
                              onChange={this.onChange}
                              type="textarea"
                              name="desc"
                              id="desc"
                              className="form-control"
                              placeholder=""
                              rows={5}
                              required
                            />
                          </div>
                        </Col>
                        <Col md="6" className="form-group">
                          <label className="pb-1">Event Title</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={item.title}
                              onChange={this.onChange}
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              placeholder="Event Title"
                              required
                            />
                          </div>

                          <label className="pb-1">Event Type </label>
                          <div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="radio-btn-content">
                                  <input
                                    type="radio"
                                    id="online"
                                    name="online"
                                    defaultChecked={
                                      this.state.type === "online"
                                    }
                                    onClick={(event) =>
                                      this.setState({
                                        type: event.target.value,
                                      })
                                    }
                                    value="online"
                                  />
                                  <label htmlFor="online">Online</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="radio-btn-content">
                                  <input
                                    type="radio"
                                    id="offline"
                                    name="offline"
                                    defaultChecked={
                                      this.state.type === "offline"
                                    }
                                    onClick={(event) =>
                                      this.setState({
                                        type: event.target.value,
                                      })
                                    }
                                    value="offline"
                                  />
                                  <label htmlFor="offline">Offline</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <label className="pb-1">Event Tag</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={item.tags}
                              onChange={this.onChange}
                              type="text"
                              id="tags"
                              name="tags"
                              className="form-control"
                              placeholder="Enter Tag"
                              required
                            />
                          </div>
                          <label className="pb-1">Enter Location</label>
                          <p className="mb-0">
                            <b>Current Location: </b>
                            {item.location.locationName}
                          </p>
                          <div className="input-group input-group-merge">
                            <div>
                              <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <FormInput
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              { className, style }
                                            )}
                                            key={index}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                          <label className="pb-1">Website URL :</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={item.url}
                              onChange={this.onChange}
                              type="text"
                              id="url"
                              name="url"
                              className="form-control"
                              placeholder="Enter Website URL"
                            />
                          </div>
                          <label className="pb-1">URL Label:</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={item.url_label}
                              onChange={this.onChange}
                              type="text"
                              id="urlLabel"
                              name="urlLabel"
                              className="form-control"
                              placeholder="Meeting Link"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        value="submit"
                        className="submit-btn"
                      >
                        Save
                      </Button>
                    </form>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer.id,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(EditEvent);
// export default EditEvent;
