import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
// import Header from "../Header/header";
import { connect } from "react-redux";
import { loginUser } from "../actions/authAction";
import PropTypes from "prop-types";
// import "../../index.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      disableBtn: false,
    };
  }
  showPassowrd = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    console.log(userData);
    this.props.loginUser(userData);
  };

  render() {
    return (
      <React.Fragment>
        <section className="hero-section ptb-30 background-img full-screen">
          <div className="container profile-container">
            <div className="row align-items-center justify-content-between  pt-sm-5 pt-md-5 pt-lg-0 login-container">
              <div className="col-md-5 col-lg-5 login-inner-container">
                <div className="card login-signup-card shadow-lg mb-0">
                  <div className="card-body px-md-5 py-5">
                    <div className="right mb-5">
                      <h5 className="h3 login-headding">Login</h5>
                    </div>

                    <form
                      className="login-signup-form"
                      method="post"
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <label className="pb-1">User Id</label>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={this.onChange}
                            value={this.state.email}
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="User Id"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label className="pb-1">Password</label>
                          </div>
                        </div>
                        <div className="input-group input-group-merge ">
                          <input
                            onChange={this.onChange}
                            value={this.state.password}
                            id="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            required
                          />

                          <FontAwesomeIcon
                            icon={faEye}
                            className="eye-icon"
                            onClick={() => this.showPassowrd()}
                          />
                        </div>
                      </div>
                      {this.props.error.error !== null ? (
                        <p style={{ color: "red" }}>
                          Invalid Usename or Password
                        </p>
                      ) : null}
                      <button
                        type="submit"
                        className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3 bg-blue"
                        id="signIn"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.authFail,
  };
};
// const mapToDispatch = (dispatch) => {
//   return (
//     dispatch(loginUser)
//   );
// };

export default connect(mapStateToProps, { loginUser })(Login);
