import React from "react";
import axios from "axios";
import PageTitle from "../../components/common/PageTitle";
import jsPDF from "jspdf";
// import 'jspdf-autotable'
import autoTable from "jspdf-autotable";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  generatePdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#my-table" });
    doc.save("table.pdf");
    doc.autoTable({
      head: [["Name", "Email", "Country"]],
      // body: [
      //   ['David', 'david@example.com', 'Sweden'],
      //   ['Castille', 'castille@example.com', 'Spain'],
      //   // ...
      // ],
    });
  };
  getblog = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tags`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.tags,
        });
      });
  };
  selectItems = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/tags/` + id, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/tag-list";
        }
      });
  };
  componentDidMount() {
    this.getblog();
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title=" Tags "
              subtitle="tags"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/add-tags")}
              >
                Add New Tag
              </Button>
            </div>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Label
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td> {item.label}</td>
                          <td>
                            <Button
                              onClick={() => this.selectItems(item.value)}
                            >
                              Delete Tag
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default TagList;
