/* eslint-disable default-case */
import React from "react";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button } from "shards-react";

class AddWhatsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      image: "",
      newBlockOrder: null,
    };
  }

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },

          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  createNotification = (type) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Posted",
          1000
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Try again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.image === "") {
      this.createNotification("error");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/addWhatsNew`,
          {
            cover: this.state.image,
            description: e.target.desc.value,
            link: e.target.link.value,
            card_type: e.target.cardType.value,
            order_by: parseInt(e.target.order_by.value),
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
      window.location.href = "/whats-new";
    }
  };

  getNewBlockOrder = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/whatsNewOrder`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({ newBlockOrder: res.data.new_block_order });
      });
  };

  componentDidMount() {
    this.getNewBlockOrder();
  }

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Add Whats new block</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form onSubmit={this.onSubmit}>
                    <label className="pb-1">Card type</label>
                    <select className="form-control" name="cardType">
                      <option value="img-txt-link">Image, text and link</option>
                      <option value="image-text">Image and text</option>
                      <option value="image">Text only</option>
                    </select>
                    <label className="pb-1" for="blockOrder">
                      Block Order:{" "}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        defaultValue={this.state.newBlockOrder}
                        onChange={this.onChange}
                        id="blockOrder"
                        type="number"
                        name="order_by"
                        className="form-control"
                        placeholder="Enter block order"
                        required
                      />
                    </div>

                    <label className="pb-1">Cover Image</label>

                    <div className="input-group input-group-merge">
                      <input
                        onChange={this.fileSelectedHandler}
                        type="file"
                        name="image"
                        className="form-control"
                        placeholder=""
                      />
                      <Button type="button" onClick={this.fileUploadHandler}>
                        Upload new Cover
                      </Button>
                    </div>

                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.state.image}
                      >
                        {this.state.image}
                      </a>
                    </div>

                    <label className="pb-1"> Description</label>
                    <div className="input-group input-group-merge">
                      <textarea
                        onChange={this.onChange}
                        type="textarea"
                        name="desc"
                        className="form-control"
                        rows={5}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="input-group input-group-merge">
                      <div></div>
                    </div>
                    <label className="pb-1">Website link :</label>
                    <div className="input-group input-group-merge">
                      <input
                        onChange={this.onChange}
                        id="link"
                        type="text"
                        name="link"
                        className="form-control"
                        placeholder="Enter website link"
                        required
                      />
                    </div>
                    <Button type="submit" className="submit-btn">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AddWhatsNew;
