import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  SET_CURRENT_USER,
  USER_LOADING,
  SET_AUTH_FAIL,
  SET_ITEMS,
} from "./type";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/register/student`, userData)
    .then((res) => {
      // history.push("/login")
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/login/admin`, userData)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token, id } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("studentId", id);
      // Set token to Auth header
      // setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .then((res) => {
      if (localStorage.getItem("jwtToken") !== null) {
        window.location.href = "/dashboard";
      }
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};
// auth fail
export const authFail = (error) => {
  return {
    type: SET_AUTH_FAIL,
    payload: error,
  };
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("id");
  localStorage.removeItem("token");
  localStorage.removeItem("studentId");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const selectItems = (updatItems) => {
  return {
    type: SET_ITEMS,
    payload: {
      newItems: updatItems,
    },
  };
};
