import React, { Component } from "react";

import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";

import PageTitle from "../../components/common/PageTitle";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class LearningResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      types: [],
      classes: [],
    };
  }

  deleteLearningResource = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/learning-resource/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/learning-resources";
        }
      });
  };

  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/learning-resources/edit");
  };
  getLearningResource = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources`)
      .then((res) => {
        this.setState({
          resources: res.data.resources,
        });
      });
  };

  getTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources-types`)
      .then((res) => {
        this.setState({
          types: res.data.resources,
        });
      });
  };
  getClasses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources-classes`)
      .then((res) => {
        this.setState({
          classes: res.data.resources,
        });
      });
  };

  getTypeTitle = (typeId) => {
    const { types } = this.state;
    const matchedType = types.find((type) => type._id === typeId);
    return matchedType ? matchedType.title : "";
  };

  getGradeTitle = (gradeId) => {
    const { classes } = this.state;
    const matchedGrade = classes.find((grade) => grade._id === gradeId);
    return matchedGrade ? matchedGrade.title : "";
  };

  componentDidMount() {
    this.getLearningResource();
    this.getTypes();
    this.getClasses();
  }

  render() {
    // Get all unique grades for the resources like 6, 9 or 10
    const getGrades = this.state.resources.map((resource) => resource.standard);
    const uniqueGrades = [...new Set(getGrades)];

    // Get All types of resources like Deep dive and academic
    const getTypes = this.state.resources.map((resource) => resource.type);
    const uniqueTypes = [...new Set(getTypes)];
    uniqueTypes.sort();

    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Learning Resources"
              subtitle="Add new resources"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() =>
                  this.props.history.push("/learning-resources/add")
                }
              >
                Add New Learning Resource
              </Button>
            </div>
          </Row>

          {uniqueGrades.map((gradeId) =>
            uniqueTypes.map((typeId) => (
              <Row>
                <Col>
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <div className="lead font-weight-bold">
                        {this.getGradeTitle(gradeId)} (
                        {this.getTypeTitle(typeId)})
                      </div>
                    </CardHeader>

                    <CardBody className="p-0 pb-3">
                      <table className="table mb-0 " id="my-table">
                        <thead className="bg-light">
                          <tr>
                            <th scope="col" className="border-0">
                              Resource Title
                            </th>
                            <th scope="col" className="border-0">
                              description
                            </th>
                            <th scope="col" className="border-0">
                              Order
                            </th>
                            <th scope="col" className="border-0 exclude-pdf">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.resources
                            .filter(
                              (resource) =>
                                resource.standard === gradeId &&
                                resource.type === typeId
                            )
                            .map((item, index) => (
                              <tr key={index}>
                                <td> {item.title}</td>

                                <td>
                                  <div className="w-200">
                                    {item.description}
                                  </div>
                                </td>
                                <td>
                                  <div className="w-200">{item.order}</div>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => this.selectItems(item._id)}
                                  >
                                    Edit Resource
                                  </Button>
                                  <br />

                                  <br />
                                  <Button
                                    theme="danger"
                                    onClick={() =>
                                      this.deleteLearningResource(item._id)
                                    }
                                  >
                                    Delete Resource
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))
          )}
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(LearningResource);
